import React from "react";
import cn from "classnames";
import styles from "./Loading.module.scss";
import LoadingSmall from "./LoadingSmall";

const FullScreenLoading = () => (
  <div
    className={cn([
      "fixed inset-0 flex justify-center h-full items-center z-50",
      styles.loadingContainer,
    ])}
  >
    <LoadingSmall color="black" />
  </div>
);

export default FullScreenLoading;
