/* eslint-disable react/require-default-props */
import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Table.module.scss";

interface BasicTableRowProps {
  children?: ReactNode;
  hideBorderBottom?: boolean;
  onClick?: React.MouseEventHandler;
}
const BasicTableRow = ({
  children,
  hideBorderBottom,
  onClick,
}: BasicTableRowProps) => (
  <tr
    className={cx([
      styles.basicTableRow,
      onClick !== null && "cursor-pointer",
      hideBorderBottom && styles.noBorderBottom,
    ])}
    onClick={onClick}
  >
    {children}
  </tr>
);

export default BasicTableRow;
