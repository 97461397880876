import React, { useState, useEffect, useContext, createContext } from "react";
import { navigate } from "gatsby";
import Cookies from "js-cookie";
import { isAuthenticated } from "../../utils/axios";
import { useAppDispatch } from "../../store";
import { resetAuctionModalState } from "../../store/auctionModal/reducer";
import { resetAuctions } from "../../store/auctions/reducer";
import { resetStore } from "../../store/stores/reducer";
import { clearUserInfo } from "../../store/user/reducer";
import FullScreenLoading from "../Loading/FullScreenLoading";

const AuthContext = createContext({
  authenticated: false,
});

export const AuthProvider = ({ authTypeUser = true, children }: any) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigateHome = () => {
    // Get URL path
    if (typeof window !== "undefined") {
      const pathName = window.location.pathname;
      navigate(`/signin/?redirect=${pathName}`);
    }
  };

  useEffect(() => {
    // Check if token changed
    const authenticated = isAuthenticated();
    if (authenticated) {
      if (authTypeUser) {
        // Check if user account type is user
        if (Cookies.get("accountType") !== "user") {
          setAuthenticated(false);
          navigateHome();
        }
      }
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
      Cookies.remove("access");
      Cookies.remove("refresh");
      Cookies.remove("account_id");
      Cookies.remove("account_type");
      Cookies.remove("accountType");
      Cookies.remove("tasksCompletedAt");
      dispatch(resetAuctionModalState());
      dispatch(resetAuctions());
      dispatch(resetStore());
      dispatch(clearUserInfo());
      navigateHome();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authenticated }}>
      {authenticated && children}
      {!authenticated && <FullScreenLoading />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
