import React from "react";
import cx from "classnames";
import styles from "./BidddyToggle.module.scss";

const BidddyToggle = ({ on, onClick }) => (
  <div
    role="button"
    tabIndex={0}
    onKeyDown={onClick}
    className={cx(styles.bidddyToggleContainer, {
      [styles.bidddyToggleOn]: on,
    })}
    onClick={onClick}
  >
    <div className={cx({ [styles.on]: on, [styles.off]: !on })}>
      <span />
    </div>
  </div>
);

export default BidddyToggle;
