import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import BasicTableHeader from "../../../../Table/BasicTableHeader";
import BasicTable from "../../../../Table/BasicTable";
import styles from "./Notifications.module.scss";
import BasicTableRow from "../../../../Table/BasicTableRow";
import BasicTableBody from "../../../../Table/BasicTableBody";
import { useAppDispatch } from "../../../../../store";
import {
  getNotificationSettings,
  NotificationSettingObject,
  UpdateNotificationSettings,
  updateNotificationSettings,
} from "../../../../../store/user/actions";
import {
  selectNotificationSettings,
  selectUpdatedNotificationSettings,
  selectUserInfoLoading,
} from "../../../../../store/user/selectors";
import BidddyToggle from "../../../../Inputs/BidddyToggle";
import { resetNotificationFlag } from "../../../../../store/user/reducer";
import FullScreenLoading from "../../../../Loading/FullScreenLoading";
import { SettingsMap } from "./utils";

const Notifications = () => {
  const dispatch = useAppDispatch();
  const [recentlyClicked, setRecentlyClicked] = useState(null);
  const [recentlyClickedValue, setRecentlyClickedValue] = useState(false);
  const notificationSettings = useSelector((state) =>
    selectNotificationSettings(state)
  ) as NotificationSettingObject;
  const updatedNotificationSettings = useSelector((state) =>
    selectUpdatedNotificationSettings(state)
  );
  const loading = useSelector((state) =>
    selectUserInfoLoading(state)
  ) as boolean;

  useEffect(() => {
    // GET CURRENT NOTIFICATION SETTINGS
    dispatch(getNotificationSettings());
  }, []);

  useEffect(() => {
    if (updatedNotificationSettings) {
      dispatch(getNotificationSettings());
      dispatch(resetNotificationFlag());
    }
  }, [updatedNotificationSettings]);

  const handleToggle = (id: number, value: boolean) => {
    const settings = {
      id,
      notification_settings: {
        value,
      },
    } as UpdateNotificationSettings;
    setRecentlyClicked(id);
    setRecentlyClickedValue(value);
    dispatch(updateNotificationSettings(settings));
  };

  const navigateBack = () => {
    navigate("/dashboard/account/");
  };

  return (
    <div className={styles.notificationSettingsContainer}>
      <div>
        <button
          type="button"
          className={styles.backButton}
          onClick={navigateBack}
        >
          Back
        </button>
        <h2>Notifications</h2>
      </div>

      <p>You can modify your notification settings here.</p>
      <BasicTable>
        <BasicTableHeader>
          <BasicTableRow>
            <td colSpan={3}>Notification Settings</td>
          </BasicTableRow>
        </BasicTableHeader>
        <BasicTableBody>
          {notificationSettings &&
            notificationSettings.data.map((setting) => (
              <BasicTableRow>
                <td>{SettingsMap[setting.type]}</td>
                <td className={styles.toggleContainer}>
                  <BidddyToggle
                    on={
                      (recentlyClicked === setting.id &&
                        recentlyClickedValue) ||
                      setting.value
                    }
                    onClick={() => handleToggle(setting.id, !setting.value)}
                  />
                </td>
              </BasicTableRow>
            ))}
        </BasicTableBody>
      </BasicTable>
      {loading && <FullScreenLoading />}
    </div>
  );
};

export default Notifications;
