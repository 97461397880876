/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import cn from "classnames";
import Cookies from "js-cookie";
import Imgix from "react-imgix";
import { navigate, Link } from "gatsby";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import SlideOut from "@ijsto/react-slideout";
import styles from "./DashboardHeader.module.scss";
import Hamburger from "./internal/Hamburger";
import { isMobile } from "../../../utils/helpers";
import { useAppDispatch } from "../../../store";
import { clearUserInfo } from "../../../store/user/reducer";
import BidddyLogo from "../../Images/BidddyLogo";
import ProfileIcon from "../../Images/ProfileIcon";
import { resetAuctionModalState } from "../../../store/auctionModal/reducer";
import { resetAuctions } from "../../../store/auctions/reducer";
import { resetStore } from "../../../store/stores/reducer";

const ConfirmationImage =
  "https://imgix.cosmicjs.com/65766150-4c7e-11ec-9940-53a088b36636-bidfootstanding-2.png";

const DashboardHeader = ({ isTransparent = false }) => {
  const dispatch = useAppDispatch();
  const [openSidebar, setOpenSidebar] = useState(false);

  useEffect(() => {
    setOpenSidebar(false);
  }, []);

  const handleLogout = () => {

    Cookies.remove("access");
    Cookies.remove("refresh");
    Cookies.remove("account_id");
    Cookies.remove("account_type");
    Cookies.remove("accountType");
    Cookies.remove("auth_failure");
    Cookies.remove("tasksCompletedAt");
    setOpenSidebar(false);
    dispatch(resetAuctionModalState());
    dispatch(resetAuctions());
    dispatch(resetStore());
    dispatch(clearUserInfo());
    navigate("/signin/");
  };

  return (
    <>
      <header
        className={cn([
          styles.dashboardHeader,
          { [styles.transparentHeader]: isTransparent },
        ])}
      >
        <div className={styles.dashboardHeaderContainer}>
          {/* TODO add hamburger bar */}
          <Hamburger onClick={() => setOpenSidebar(!openSidebar)} />
          <Link aria-label="Dashboard" to="/dashboard/">
            <BidddyLogo />
          </Link>
          <Link to="/dashboard/account/" className={styles.profile}>
            <ProfileIcon />
          </Link>
        </div>
      </header>
      <SlideOut
        overflowShouldLock
        noCloseComponent
        isOpen={openSidebar}
        onClose={() => setOpenSidebar(false)}
        width={isMobile.any() ? "100%" : "400px"}
        slideFrom="left"
        offsetTop="0px"
      >
        <div className={styles.menuNav}>
          <div className="w-full h-full flex flex-col bg-bidddyPink relative">
            <div
              role="button"
              onKeyDown={() => setOpenSidebar(false)}
              tabIndex={0}
              className={styles.hamburgerBox}
              onClick={() => setOpenSidebar(false)}
            >
              <div className={styles.hamburgerInner} />
            </div>
            <div className={styles.navLinks}>
              <Link to="/" onClick={() => setOpenSidebar(false)}>
                HOME
              </Link>
              <Link to="/dashboard/" onClick={() => setOpenSidebar(false)}>
                DASHBOARD
              </Link>
              <Link
                to="/dashboard/auctions/"
                onClick={() => setOpenSidebar(false)}
              >
                MY AUCTIONS
              </Link>
              {/* <Link to="/dashboard/boards/">
                Boards
              </Link> */}
              {/* <Link to="/dashboard/payment/">
                Payment
              </Link> */}
              <Link to="/learn/" onClick={() => setOpenSidebar(false)}>
                LEARN
              </Link>
              {/* <Link to="/learn/faq/" onClick={() => setOpenSidebar(false)}>
                SUPPORT
              </Link> */}
              <Link
                to="/dashboard/account/"
                onClick={() => setOpenSidebar(false)}
              >
                ACCOUNT
              </Link>
            </div>
            <div className={styles.smallLinksBase}>
              <button
                type="button"
                className="font-inter text-white text-left text-lg"
                onClick={handleLogout}
              >
                <b>Log Out</b>
              </button>
            </div>
            <div className={styles.smallLinks}>
              <Link
                to="/contact-us/"
                className="text-white"
                onClick={() => setOpenSidebar(false)}
              >
                <strong>Contact Us</strong>
              </Link>
              <Link className="text-white" to="/privacy-policy/">
                <i>Privacy Policy</i>
              </Link>
              <Link to="/terms-of-service/" className="text-white">
                <i>Terms of Service</i>
              </Link>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <Imgix
              src={`${ConfirmationImage}?auto=format`}
              height={645}
              width={206}
              htmlAttributes={{
                alt: "Confirmation",
                src: `${ConfirmationImage}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
          <div className={styles.melonContainer} />
        </div>
      </SlideOut>
    </>
  );
};

export default DashboardHeader;
