export const selectUserInfo = (state) => state.user.userInfo;
export const selectAvailableMarkets = (state) => state.user.markets;
export const selectUserInfoLoading = (state) => state.user.loading;
export const selectRequestingUserInfo = (state) =>
  state.user.requestingUserInfo;
export const selectUpdatedNotificationSettings = (state) =>
  state.user.updatedNotificationSettings;
export const selectNotificationSettings = (state) =>
  state.user.notificationSettings;
export const selectInstagramBasicUrl = (state) =>
  state.user.instagramBasicAuthUrl;
export const selectInstagramBasicAccounts = (state) =>
  state.user.instagramBasicAccounts;
export const selectCurrentInstagramBasicAccount = (state) =>
  state.user.currentInstagramBasicAccount;
export const selectInstagramErrors = (state) => state.user.errors;
export const selectPathLocation = (state) => state.user.location;
export const selectLastPage = (state) => state.user.lastPage;

export const selectAuctionSettings = (state) => state.user.auctionSettings;
export const selectUpdatedAuctionSettings = (state) =>
  state.user.updatedAuctionSettings;

export const selectInstagramAccountsLoading = (state) =>
  state.user.accountsLoading;
