import React from "react";
import { Link, navigate } from "gatsby";
import Cookies from "js-cookie";
import BidddyLogo from "../../Images/BidddyLogo";
import styles from "./Footer.module.scss";
import { useAppDispatch } from "../../../store";
import { clearUserInfo } from "../../../store/user/reducer";
import { resetAuctionModalState } from "../../../store/auctionModal/reducer";
import { resetAuctions } from "../../../store/auctions/reducer";
import { resetStore } from "../../../store/stores/reducer";

const Footer = () => {
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    Cookies.remove("access");
    Cookies.remove("refresh");
    Cookies.remove("account_id");
    Cookies.remove("account_type");
    Cookies.remove("accountType");
    Cookies.remove("auth_failure");
    Cookies.remove("tasksCompletedAt");
    dispatch(resetAuctionModalState());
    dispatch(resetAuctions());
    dispatch(resetStore());
    dispatch(clearUserInfo());
    navigate("/signin/");
  };
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerItemsContainer}>
        <div className={styles.logoContainer}>
          <Link to="/dashboard/">
            <BidddyLogo />
          </Link>
        </div>
        <div className={styles.footerLinksContainer}>
          <div className={styles.linkCol}>
            <Link to="/dashboard/">Dashboard</Link>
            <Link to="/dashboard/auctions/">Auctions</Link>
            <Link to="/learn/">Learn</Link>
            {/* <Link to="/learn/faq/">
              Support
            </Link> */}
            <Link to="/dashboard/account/">Account</Link>
          </div>
          <div className={styles.linkCol}>
            <Link to="/contact-us/">Contact Us</Link>
            <div
              role="button"
              onKeyDown={() => handleLogout()}
              tabIndex={0}
              onClick={() => handleLogout()}
            >
              <p className="font-inter text-white">
                <b>Log Out</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.finePrint}>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <p>|</p>
        <Link to="/terms-of-service">Terms of Service</Link>
      </div>
    </footer>
  );
};

export default Footer;
