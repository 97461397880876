import React from "react";
import { AuthProvider } from "../../../components/Auth/auth";
import DashboardLayout from "../../../components/DashboardLayout";
import Notifications from "../../../components/PageComponents/Dashboard/Account/NotificationSettings/Notifications";

const AccountPage = () => (
  <AuthProvider>
    <DashboardLayout title="Bidddy - Your Account | Notification Settings">
      <Notifications />
    </DashboardLayout>
  </AuthProvider>
);

export default AccountPage;
