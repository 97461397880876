/* eslint-disable react/require-default-props */
import React, { ReactNode } from "react";
import Helmet from "react-helmet";
import DashboardHeader from "./Navigation/DashboardHeader";
import styles from "./DashboardLayout.module.scss";
import Footer from "./Navigation/Footer";

type Props = {
  children?: ReactNode;
  title?: string;
  style?: object;
  hideNav?: boolean;
  hideFooter?: boolean;
};
const isProduction = process.env.GATSBY_PUBLIC_ENV === "production";
const DashboardLayout = ({
  children,
  title = "This is the default title",
  style,
  hideNav = false,
  hideFooter = false,
}: Props) => (
  <>
    <Helmet>
      <html lang="en" className={styles.htmlContainer} />
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width, maximum-scale=1"
      />
      <link
        rel="preload"
        as="style"
        href="https://use.typekit.net/bal2stb.css"
      />
      <link rel="stylesheet" href="https://use.typekit.net/bal2stb.css" />
      {/* GOOGLE ANALYTICS */}
      {isProduction && (
        <>
          <script
            defer
            src="https://www.googletagmanager.com/gtag/js?id=UA-181709414-1"
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-181709414-1', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}
    </Helmet>
    {!hideNav && <DashboardHeader />}
    <main className={styles.mainContainer} style={style}>
      {children}
    </main>
    {!hideFooter && <Footer />}
  </>
);

export default DashboardLayout;
